<!-- ou-footer v2.0.0.542 -->
<div
  id="ou-org-footer"
  class="ou-footer"
  role="contentinfo"
  aria-label="ou site footer"
>
  <div class="ou-container">
    <div class="ou-header">
      <div class="ou-crest">
        <img
          src="/ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542"
          srcset="/ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542 1x, /ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542 2x"
          alt="The Open University Crest"
          title="The Open University Crest"
        />
      </div>
      <div class="ou-mob-crest">
        <img
          src="/ouheaders/gui/OU_Crest_SVG_Mobile.svg?2.0.0.542"
          srcset="/ouheaders/gui/OU_Crest_SVG_Mobile.svg?2.0.0.542 1x, /ouheaders/gui/OU_Crest_SVG_Mobile.svg?2.0.0.542 2x"
          alt="The Open University Crest"
          title="The Open University Crest"
        />
      </div>
      <div class="ou-footer-title">
        <h2 data-translate="true">The Open University</h2>
      </div>
    </div>
    <div class="ou-footer-nav">
      <div id="ou-ia-student" class="ou-ia-footer" role="tablist">
        <div class="ou-row">
          <div class="ou-grid3">
            <ul>
              <li>
                <h4
                  class="ou-collapsible-footer"
                  id="ou-footer-ou-community-toggle"
                  role="tab"
                  tabindex="-1"
                >
                  <span>Follow us on Social media</span>
                  <span class="ou-mobile-menu-toggle icon-up">
                    <img
                      src="/ouheaders/gui/sprite.png?2.0.0.542"
                      srcset="/ouheaders/gui/sprite.png?2.0.0.542 1x, /ouheaders/gui/sprite-x3.png?2.0.0.542 2x"
                      alt="Menu toggle"
                      title="Menu toggle"
                    />
                  </span>
                </h4>
                <div
                  class="ou-mobile-menu-toggle"
                  id="ou-footer-ou-community"
                  role="tabpanel"
                >
                  <ul class="ou-inline">
                    <li class="facebook">
                      <a
                        href="https://www.facebook.com/theopenuniversity"
                        title="Facebook"
                      >
                        <span>
                          <img
                            src="/ouheaders/gui/Social_facebook.svg?2.0.0.542"
                            srcset="/ouheaders/gui/Social_facebook.svg?2.0.0.542 1x, /ouheaders/gui/Social_facebook.svg?2.0.0.542 2x"
                            alt="Facebook"
                            title="Facebook"
                          />
                        </span>
                      </a>
                    </li>
                    <li class="twitter">
                      <a
                        href="https://twitter.com/OpenUniversity"
                        title="Twitter"
                      >
                        <span>
                          <img
                            src="/ouheaders/gui/Social_twitter.svg?2.0.0.542"
                            srcset="/ouheaders/gui/Social_twitter.svg?2.0.0.542 1x, /ouheaders/gui/Social_twitter.svg?2.0.0.542 2x"
                            alt="Twitter"
                            title="Twitter"
                          />
                        </span>
                      </a>
                    </li>
                    <li class="youtube">
                      <a
                        href="https://www.youtube.com/user/TheOpenUniversity"
                        title="Youtube"
                      >
                        <span>
                          <img
                            src="/ouheaders/gui/Social_youtube.svg?2.0.0.542"
                            srcset="/ouheaders/gui/Social_youtube.svg?2.0.0.542 1x, /ouheaders/gui/Social_youtube.svg?2.0.0.542 2x"
                            alt="YouTube"
                            title="YouTube"
                          />
                        </span>
                      </a>
                    </li>
                    <li class="linkedin">
                      <a
                        href="https://www.linkedin.com/edu/school?id=12659&amp;trk=edu-cp-title"
                        title="LinkedIn"
                      >
                        <span>
                          <img
                            src="/ouheaders/gui/Social_linkedin.svg?2.0.0.542"
                            srcset="/ouheaders/gui/Social_linkedin.svg?2.0.0.542 1x, /ouheaders/gui/Social_linkedin.svg?2.0.0.542 2x"
                            alt="LinkedIn"
                            title="LinkedIn"
                          />
                        </span>
                      </a>
                    </li>
                    <!--<li class="googleplus">
                  <a
                    href="https://plus.google.com/+TheOpenUniversity"
                    title="Google+">
                    <span>
                      <img src="/ouheaders/gui/sprite.png?2.0.0.542" srcset="/ouheaders/gui/sprite.png?2.0.0.542 1x, /ouheaders/gui/sprite-x3.png?2.0.0.542 2x" alt="Google+" title="Google+" />
                    </span>
                  </a>
                </li>-->
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="ou-grid3">
            <ul class="ou-mobile-menu-toggle" id="ou-footer-support">
              <li>
                <a
                  href="https://www.open.ac.uk/about/main/strategy-and-policies/policies-and-statements/website-accessibility-hub/accessibility-statement-open"
                  >Accessibility statement</a
                >
              </li>
              <li>
                <a
                  href="https://www.open.ac.uk/about/main/strategy-and-policies/policies-and-statements/conditions-use-open-university-websites"
                  >Conditions of use</a
                >
              </li>
              <li>
                <a
                  href="https://www.open.ac.uk/about/main/strategy-and-policies/policies-and-statements/copyright-ou-websites"
                  >Copyright</a
                >
              </li>
              <li>
                <a href="https://www.open.ac.uk/wales/cy">Cymraeg</a>
              </li>
            </ul>
          </div>
          <div class="ou-grid3">
            <ul>
              <li>
                <ul class="ou-mobile-menu-toggle" id="ou-footer-study">
                  <li>
                    <a
                      href="https://www.open.ac.uk/about/main/strategy-and-policies/policies-and-statements/website-privacy-ou"
                      >Privacy policy</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.open.ac.uk/about/main/strategy-and-policies/policies-and-statements/cookie-use-ou-website"
                      >Cookie policy</a
                    >
                  </li>
                  <li>
                    <a
                      href="#"
                      onclick="CassieWidgetLoader.Widget.showModal(); managePreferenceSetInterval();"
                      >Manage cookie preferences</a
                    >
                  </li>
                  <li>
                    <a href="https://help.open.ac.uk/documents/policies">
                      Student Policies and Regulations
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="ou-grid3">
            <ul>
              <li>
                <ul class="ou-mobile-menu-toggle" id="ou-footer-policy">
                  <li>
                    <a href="https://www2.open.ac.uk/students/charter">
                      Student Charter
                    </a>
                  </li>
                  <li>
                    <a href="https://status.open.ac.uk"> System Status </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="ou-small-print">
        <div class="ou-crest-icon">
          <img
            src="/ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542"
            srcset="/ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542 1x, /ouheaders/gui/OU_Crest_SVG.svg?2.0.0.542 2x"
            alt="The Open University Crest"
            title="The Open University Crest"
          />
        </div>
        <p class="ou-copyright" id="ou-copyright" style="display:none">
          <small>
            &#xA9;<span id="ou-copyright-year"
              ><a style="display:none">.</a></span
            >.
            <span data-translate="true" id="ou-footer-statement"
              ><a style="display:none">.</a></span
            ></small
          >
        </p>
      </div>
    </div>
  </div>
</div>

<!-- End ou-footer v2.0.0.542 -->

<style>
  #ou-ia-student {
    display: block !important;
    visibility: visible !important;
    margin-left: 0 !important;
  }
</style>
